<!--  -->
<template>
  <div class="Discounts">
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" @click="back()" />
      <div class="title">领惠民券</div>
    </div>
    <div class="juan_cont">
      <div class="juan_box" @click="lingjuan(1)">
        <img src="../../images/ins/j1.png" alt="" srcset="" />
      </div>
      <div class="juan_box" @click="lingjuan(2)">
        <img src="../../images/ins/j2.png" alt="" srcset="" />
      </div>
      <div class="juan_box" @click="lingjuan(3)">
        <img src="../../images/ins/j3.png" alt="" srcset="" />
      </div>
    </div>
    <navBer :navNum="1"></navBer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Toast, Icon } from "vant";
export default {
  name: "Discounts",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [Toast.name]: Toast,
    [Icon.name]: Icon,
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
    lingjuan(i) {
      switch (i) {
        case 1:
        Toast('文轩九月');
          break;
        case 2:
        Toast('主会场');
          break;
        case 3:
        Toast('分会场');
          break;
        default:
          break;
      }
    
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.juan_cont {
  padding: 0 27px;
  .juan_box {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
</style>